<template>
  <v-row align="center"
         justify="center"
         class="flex-wrap"
         dense>
    <v-expand-transition mode="out-in">
      <v-col v-show="open"
             cols="12">
        <slot />
      </v-col>
    </v-expand-transition>
    <v-col class="flex-grow-0">
      <v-btn
        class="mb-0"
        small
        icon
        text
        @click="open = !open">
        <v-badge v-model="hasActiveFilters"
                 color="accent"
                 overlap
                 bottom
                 class="badge-sm">
          <template v-slot:badge>
            <v-icon x-small>
              mdi-filter
            </v-icon>
          </template>
          <v-icon :class="{'panel-open':open}">
            mdi-chevron-down
          </v-icon>
        </v-badge>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SearchLayoutDrawer',
  props: {
    drawerFilters: {
      type: Array,
      required: true,
    },
    filtersCollection: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      open: false,
      hasActiveFilters: false,
    };
  },
  watch: {
    filtersCollection: {
      handler () {
        this.calculateActiveFilters();
      },
      deep: true,
    },
  },
  created () {
    this.calculateActiveFilters();
  },
  methods: {
    calculateActiveFilters () {
      this.hasActiveFilters = false;
      this.drawerFilters.forEach((item) => {
        if (this.filtersCollection[item]) {
          if (Array.isArray(this.filtersCollection[item])) {
            if (this.filtersCollection[item].length > 0) {
              this.hasActiveFilters = true;
            }
          } else {
            this.hasActiveFilters = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
  .panel-open {
    transform: scaleY(-1);
  }
</style>
