<template>
  <v-sheet>
    <v-row dense>
      <slot name="search-left" />
      <v-col>
        <v-text-field v-if="!noQuickSearch"
                      v-model="quickSearch"
                      class="py-0"
                      :placeholder="$t('Quick search')"
                      type="search"
                      prepend-icon="mdi-magnify"
                      clearable
                      hide-details
                      @input="onQuickSearch" />
      </v-col>
      <v-col class="flex-grow-0 text-no-wrap">
        <slot name="search-right" />
      </v-col>
      <v-col v-if="!noColumnSelect"
             class="flex-grow-0">
        <v-menu ref="menu"
                v-model="columnSettingMenu"
                transition="slide-y-transition"
                max-height="80%"
                max-width="90%"
                min-width="350px"
                :activator="$refs.tableSettings"
                bottom
                left
                offset-y
                :close-on-content-click="false">
          <template v-slot:activator="{on}">
            <v-btn color="primary"
                   dark
                   class="table-options-button"
                   v-on="on">
              <v-badge color="accent"
                       bottom
                       right
                       :value="shownColumns.length!==columns.length || groupingActive">
                <template v-slot:badge>
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </template>
                <v-icon>mdi-tune</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <v-list dense
                  class="data-table-columns-settings">
            <v-list-item>
              <v-list-item-content>
                <v-btn color="accent"
                       block
                       dark
                       small
                       @click="columnSettingMenu=false">
                  {{$t('Close')}}
                </v-btn>
              </v-list-item-content>
              <v-list-item-action class="justify-center px-1">
                <trol-tooltip-wrapper top>
                  <template v-slot:activator="{on}">
                    <v-btn color="accent"
                           icon
                           small
                           v-on="on"
                           @click="onAllGroupingClick">
                      <v-icon small>
                        mdi-arrow-split-horizontal
                      </v-icon>
                    </v-btn>
                  </template>
                  {{$t('Grouping')}}
                </trol-tooltip-wrapper>
                <trol-tooltip-wrapper top>
                  <template v-slot:activator="{on}">
                    <v-btn color="grey"
                           icon
                           small
                           v-on="on"
                           @click="onNoneGroupingClick">
                      <v-icon small>
                        mdi-format-vertical-align-center
                      </v-icon>
                    </v-btn>
                  </template>
                  {{$t('Grouping')}}
                </trol-tooltip-wrapper>
              </v-list-item-action>
              <v-list-item-action class="justify-center px-1">
                <trol-tooltip-wrapper top>
                  <template v-slot:activator="{on}">
                    <v-btn color="primary"
                           icon
                           small
                           v-on="on"
                           @click="onAllVisibilityClick">
                      <v-icon small>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  {{$t('Visibility')}}
                </trol-tooltip-wrapper>
                <trol-tooltip-wrapper top>
                  <template v-slot:activator="{on}">
                    <v-btn color="grey"
                           icon
                           small
                           v-on="on"
                           @click="onNoneVisibilityClick">
                      <v-icon small>
                        mdi-eye-off
                      </v-icon>
                    </v-btn>
                  </template>
                  {{$t('Visibility')}}
                </trol-tooltip-wrapper>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-slider
                  v-model="computedCellFontSize"
                  append-icon="mdi-magnify-plus-outline"
                  prepend-icon="mdi-magnify-minus-outline"
                  :step="0.05"
                  :max="1.5"
                  :min="0.5"
                  always-dirty
                  @click:prepend="computedCellFontSize = (computedCellFontSize - 0.05) || 0.5"
                  @click:append="computedCellFontSize = (computedCellFontSize + 0.05) || 1.5" />
              </v-list-item-content>
              <v-list-item-action class="title text--secondary">
                {{Math.round(computedCellFontSize * 100)}}%
              </v-list-item-action>
            </v-list-item>
            <template v-for="column in columnsWithControls">
              <v-list-item :key="column.name">
                <v-list-item-content>
                  {{column.label}}
                </v-list-item-content>
                <v-list-item-action class="px-1 my-2 column-switch">
                  <v-switch v-if="column.groupable"
                            v-model="groupingInternal"
                            :value="column.name"
                            color="accent"
                            @change="groupingChanged(column)" />
                </v-list-item-action>
                <v-list-item-action class="px-1 my-2 column-switch">
                  <v-switch v-if="!column.persistent && isColumnShown(column)"
                            v-model="shownColumnsInternal"
                            :value="column.name"
                            :disabled="shownColumnsInternal.length < 2 && shownColumnsInternal.indexOf(column.name) > -1"
                            color="primary"
                            @change="shownColumnsChanged(column)" />
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <div class="row data-table-row">
      <table class="trol-data-table full-width highlight striped-even responsive vertical-separator trol-data-table-table compact">
        <thead>
          <slot :name="'thead'">
            <tr>
              <th v-if="selectable"
                  id="selection-column">
                <v-checkbox
                  hide-details
                  color="primary"
                  @change="toggleSelection($event)" />
              </th>
              <th v-for="column in filteredColumns"
                  :key="column.name"
                  :class="getHeaderClass(column)"
                  :style="headerStyle(column)"
                  @click="column.sortable && changeSorting(column.name)">
                <v-row no-gutters
                       class="text-no-wrap align-end flex-nowrap header-content">
                  <v-col class="justify-center">
                    <slot :name="'th-'+column.name"
                          :column="column">
                      <template v-if="column.headerForceWrap">
                        <span class="text-pre-wrap">{{column.label.split(' ').join("\n")}}</span>
                      </template>
                      <template v-else>
                        {{column.label}}
                      </template>
                    </slot>
                  </v-col>
                  <v-col v-if="column.sortable"
                         class="sort-icon column justify-center flex-grow-0">
                    <v-icon v-if="sortingInternal.direction === 0 || sortingInternal.name !== column.name"
                            size=".8em"
                            class="grey--text text--lighten-2">
                      mdi-menu-up
                    </v-icon>
                    <v-icon v-if="sortingInternal.direction > 0 && sortingInternal.name === column.name"
                            size=".8em"
                            color="primary">
                      mdi-menu-up
                    </v-icon>
                    <v-icon v-if="sortingInternal.direction < 0 && sortingInternal.name === column.name"
                            size=".8em"
                            color="primary">
                      mdi-menu-down
                    </v-icon>
                  </v-col>
                </v-row>
              </th>
            </tr>
          </slot>
        </thead>
        <tbody>
          <tr v-for="(row, index) in filteredData"
              :key="`row-${index}`"
              :class="{active:selected && selected.indexOf(row.id)!==-1}">
            <td v-if="selectable"
                class="selection-column">
              <v-checkbox v-if="selectableCallback ? selectableCallback(row) : true"
                          :id="index+''"
                          v-model="selected"
                          :value="row.id"
                          color="primary"
                          primary
                          hide-details />
            </td>
            <td v-for="column in filteredColumns"
                :key="column.name"
                :data-th="column.label"
                :class="getCellClass(column)"
                :style="cellStyle(column)"
                class="data-table-cell">
              <slot v-if="!column.alias"
                    :name="'cell-'+column.name"
                    :cell="column.path ? getValue(row, column.path) : row[column.name]"
                    :row="row"
                    :column="column">
                {{column.path ? getValue(row, column.path) : row[column.name]}}
              </slot>
              <slot v-else
                    :name="'cell-alias-'+column.alias"
                    :cell="column.path ? getValue(row, column.path) : row[column.name]"
                    :row="row"
                    :column="column">
                {{column.path ? getValue(row, column.path) : row[column.name]}}
              </slot>
            </td>
          </tr>
        </tbody>
        <thead v-if="bottomHeader"
               class="bottom-header">
          <slot :name="'thead-bottom'">
            <tr>
              <th v-for="column in filteredColumns"
                  :key="column.name"
                  :class="getHeaderClass(column)"
                  :style="headerStyle(column)"
                  @click="column.sortable && changeSorting(column.name)">
                <v-row class="flex-nowrap header-content"
                       align="end"
                       no-gutters>
                  <v-col class="justify-center">
                    <slot :name="'th-'+column.name"
                          :column="column">
                      <template v-if="column.headerForceWrap">
                        <span class="text-pre-wrap">{{column.label.split(' ').join("\n")}}</span>
                      </template>
                      <template v-else>
                        {{column.label}}
                      </template>
                    </slot>
                  </v-col>
                  <v-col v-if="column.sortable"
                         class="sort-icon column justify-center">
                    <v-icon v-if="sortingInternal.direction === 0 || sortingInternal.name !== column.name"
                            size=".8em"
                            class="grey--text text--lighten-2">
                      mdi-menu-up
                    </v-icon>
                    <v-icon v-if="sortingInternal.direction > 0 && sortingInternal.name === column.name"
                            size=".8em"
                            color="primary">
                      mdi-menu-up
                    </v-icon>
                    <v-icon v-if="sortingInternal.direction < 0 && sortingInternal.name === column.name"
                            size=".8em"
                            color="primary">
                      mdi-menu-down
                    </v-icon>
                  </v-col>
                </v-row>
              </th>
            </tr>
          </slot>
        </thead>
        <tfoot v-if="!noCount">
          <slot :name="'tfoot'">
            <tr v-if="computedTotals">
              <td v-for="column in filteredColumns"
                  :key="column.name"
                  :data-th="column.label"
                  :class="getCellClass(column)"
                  :style="cellStyle(column)">
                <slot v-if="!column.alias"
                      :name="'foot-'+column.name"
                      :cell="computedTotals[column.name]"
                      :row="computedTotals"
                      :column="column">
                  {{computedTotals[column.name]}}
                </slot>
                <slot v-else
                      :name="'foot-alias-'+column.alias"
                      :cell="computedTotals[column.name]"
                      :row="computedTotals"
                      :column="column">
                  {{computedTotals[column.name]}}
                </slot>
              </td>
            </tr>
            <tr>
              <td v-if="totals && totals.total"
                  :colspan="filteredColumns.length"
                  class="text-left">
                {{$t('Showing')}}: <b>{{filteredData.length}}</b> | {{$t('Total')}}: <b>{{totals.total}}</b>
              </td>
              <td v-else
                  :colspan="filteredColumns.length"
                  class="text-left">
                {{$t('Total')}}: <b>{{filteredData.length}}</b>
              </td>
            </tr>
          </slot>
        </tfoot>
      </table>
      <v-row v-if="totals && totals.total > 100"
             justify="center"
             no-gutters
             class="pb-3">
        <v-col>
          <v-pagination v-model="computedPage"
                        :length="totalPages"
                        :total-visible="11"
                        circle />
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import extend from 'lodash/extend';
import debounce from 'debounce';
import {getValue} from '@/utils/PathAccessor';
import {Storage} from '@/services/LocalStorage';

export default {
  name: 'TrolDataTable',
  props: {
    totals: {
      type: Object,
      default () {
        return undefined;
      },
    },
    data: {
      type: [Array],
      default () {
        return [];
      },
    },
    selectable: {
      type: [Boolean],
      default: false,
    },
    selectableCallback: {
      type: Object,
      default () {
        return undefined;
      },
    },
    columns: {
      type: Array,
      default () {
        return [];
      },
    },
    shownColumns: {
      type: Array,
      default () {
        return [];
      },
    },
    sorting: {
      type: [Array, Object],
      default () {
        return undefined;
      },
    },
    grouping: {
      type: [Array, Object],
      default () {
        return undefined;
      },
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 0,
    },
    externalSorting: {
      type: [Boolean, Number],
      default: false,
    },
    externalFilter: {
      type: [Boolean, Number],
      default: false,
    },
    externalGrouping: {
      type: [Boolean, Number],
      default: false,
    },
    debounceExternal: {
      type: Boolean,
      default: false,
    },
    noColumnSelect: {
      type: Boolean,
      default: false,
    },
    noQuickSearch: {
      type: Boolean,
      default: false,
    },
    noCount: {
      type: Boolean,
      default: false,
    },
    bottomHeader: {
      type: Boolean,
      default: false,
    },
    cellFontSize: {
      type: Number,
      default: undefined,
    },
    storageKey: {
      type: String,
      default: undefined,
    },
  },
  data () {
    return {
      quickSearch: '',
      sortingInternal: {},
      shownColumnsInternal: [],
      groupingInternal: [],
      columnSettingMenu: false,
      noEvents: false,
      cellFontSizeInternal: 1,
      selected: [],
    };
  },
  computed: {
    computedCellFontSize: {
      get () {
        return this.cellFontSize !== undefined ? this.cellFontSize : this.cellFontSizeInternal;
      },
      set (value) {
        this.cellFontSizeInternal = value;
        this.$emit('cellFontSize', value);
        if (this.storageKey !== undefined) {
          Storage.set('data-table-' + this.storageKey, value);
        }
      },
    },
    computedPage: {
      get () {
        return this.page + 1;
      },
      set (value) {
        this.$emit('changePage', value - 1);
      },
    },
    totalPages () {
      return this.totals ? Math.ceil(this.totals.total / 50) : 0;
    },
    filteredColumns () {
      return this.columns.filter((column) => {
        return this.isColumnShown(column)
          && (
            column.persistent
            || this.noColumnSelect
            || this.shownColumnsInternal.indexOf(column.name) > -1
          );
      });
    },
    columnsWithControls () {
      return this.columns.filter(column => this.columnControlActive(column));
    },
    computedColumns () {
      return this.columns.filter(column => typeof column.computed === 'function');
    },
    groupableColumns () {
      return this.columns.filter(column => column.groupable === true);
    },
    groupFunctionColumns () {
      return this.columns.filter(column => typeof column.groupFunction === 'function');
    },
    groupingActive () {
      return this.groupableColumns.length > 0 && this.groupableColumns.length !== this.groupingInternal.length;
    },
    filteredData () {
      let filtered    = [...this.data];
      const filterKey = this.quickSearch ? this.quickSearch.toLowerCase() : undefined;

      if (this.computedColumns.length > 0) {
        filtered = filtered.map((row) => {
          this.computedColumns.forEach((column) => {
            row[column.name] = column.computed(row, column);
          });
          return row;
        });
      }

      if (!this.externalGrouping && this.groupableColumns.length > 0) {
        filtered = filtered
          .reduce(
            (accumulator, current) => {
              let key = '';
              current = extend(true, {}, current);
              this.groupableColumns.forEach((column) => {
                if (this.groupingInternal.indexOf(column.name) > -1) {
                  key += String(current[column.name]);
                } else {
                  current[column.name] = undefined;
                }
              });

              const existing = accumulator.find((row) => row && row.key === key);
              if (typeof existing !== 'undefined') {
                this.groupFunctionColumns.forEach((column) => {
                  existing.value[column.name] = column.groupFunction(existing.value[column.name], current[column.name], existing.value);
                });
              } else {
                accumulator.push({key: key, value: current});
              }
              return accumulator;
            },
            [],
          )
          .map(row => row.value);
      }

      if (!this.externalFilter && filterKey) {
        filtered = filtered.filter(function (row) {
          return Object.keys(row).some(key => {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }

      if (this.sortingInternal.direction && !this.externalSorting) {
        filtered = filtered.slice().sort((a, b) => {
          a = a[this.sortingInternal.name];
          b = b[this.sortingInternal.name];
          return (a === b ? 0 : a > b ? 1 : -1) * this.sortingInternal.direction;
        });
      }

      return filtered;
    },
    computedTotals () {
      if (!this.totals) return;
      const filtered = this.totals;
      if (this.computedColumns.length > 0) {
        this.computedColumns.forEach((column) => {
          filtered[column.name] = column.withTotals ? column.computed(filtered, column) : undefined;
        });
      }
      return filtered;
    },
  },
  watch: {
    selected (val) {
      this.$emit('selectionRowsChanged', val);
    },
  },
  created () {
    this.populateState();
    this.cellFontSizeInternal = Storage.has('data-table-' + this.storageKey) ? Storage.get('data-table-' + this.storageKey) : 1;
  },
  methods: {
    getValue: getValue,
    populateState (emitEvents = true) {
      const sortingDefaults = {name: null, direction: 0};

      this.sortingInternal = typeof this.sorting === 'object'
        ? extend({}, sortingDefaults, this.sorting)
        : sortingDefaults;

      this.shownColumnsInternal = this.shownColumns.length
        ? this.shownColumns
        : this.columns.map(item => item.name);

      this.groupingInternal = Array.isArray(this.grouping)
        ? this.grouping
        : this.columns.filter(item => item.groupable).map(item => item.name);
      if (emitEvents && !this.noEvents) {
        this.$emit('sorting', this.sortingInternal);
        this.$emit('grouping', this.groupingInternal);
        this.$emit('shownColumns', this.shownColumnsInternal);
      }
    },
    toggleSelection (isAllSelected) {
      if (isAllSelected) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    },
    selectAll () {
      this.selected = this.data
        .filter(row => this.selectableCallback ? this.selectableCallback(row) : true)
        .map(row => row.id);
    },
    unselectAll () {
      this.selected = [];
    },
    cellStyle (column) {
      const style = {
        'max-width': column.maxWidth,
      };

      if (this.computedCellFontSize !== 1) {
        style['font-size'] = this.computedCellFontSize + 'em';
      }

      return style;
    },
    headerStyle (column) {
      const style = {
        'max-width': column.maxWidth,
      };

      if (this.computedCellFontSize !== 1) {
        style['font-size'] = this.computedCellFontSize + 'em';
      }

      return style;
    },
    getCellClass (column) {
      return extend(
        {
          'text-right': column.right,
          'text-truncate': column.maxWidth,
          'text-no-wrap': column.maxWidth || column.noWrap,
        },
        column.cellClass,
      );
    },
    getHeaderClass (column) {
      return extend(
        {'cursor-pointer': column.sortable, 'text-right': column.right},
        column.headerClass,
      );
    },
    changeSorting (column) {
      if (this.sortingInternal.name !== column) {
        this.sortingInternal.name      = column;
        this.sortingInternal.direction = 1;
      } else {
        this.sortingInternal.direction = -this.sortingInternal.direction;
      }
      if (this.debounceExternal) {
        this.debounceSortingEvent();
      } else {
        this.$emit('sorting', this.sortingInternal);
      }
    },
    groupingChanged (column) {
      if (this.groupingInternal.indexOf(column.name) < 0) {
        const index = this.shownColumnsInternal.indexOf(column.name);
        index > -1 && this.shownColumnsInternal.splice(index, 1);
        if (this.sortingInternal.name === column.name) {
          this.sortingInternal.name = '';
          !this.noEvents && this.$emit('sorting', this.sortingInternal);
        } else {
          let shown = false;
          this.filteredColumns.forEach((item) => {
            if (this.sortingInternal.name === item.name) {
              shown = true;
            }
          });
          if (!shown) {
            this.sortingInternal.name = '';
            !this.noEvents && this.$emit('sorting', this.sortingInternal);
          }
        }
        this.shownColumnsChanged(column);
      }
      if (this.debounceExternal) {
        this.debounceGroupingEvent();
      } else {
        !this.noEvents && this.$emit('grouping', this.groupingInternal);
      }
    },
    shownColumnsChanged (column) {
      if (column.groupable
        && this.shownColumnsInternal.indexOf(column.name) > -1
        && this.groupingInternal.indexOf(column.name) < 0
      ) {
        this.groupingInternal.push(column.name);
        this.groupingChanged(column);
      }
      !this.noEvents && this.$emit('shownColumns', this.shownColumnsInternal);
    },
    onQuickSearch () {
      if (this.externalFilter) {
        if (this.debounceExternal) {
          this.debounceQuickFilterEvent();
        } else {
          this.$emit('quickFilter', this.quickSearch);
        }
      }
    },
    debounceSortingEvent: debounce(function () {
      this.$emit('sorting', this.sortingInternal);
    }, 750),
    debounceQuickFilterEvent: debounce(function () {
      this.$emit('quickFilter', this.quickSearch);
    }, 1000),
    debounceGroupingEvent: debounce(function () {
      this.$emit('grouping', this.groupingInternal);
    }, 1000),
    columnControlActive (column) {
      return column.groupable
        || (!column.persistent
          && this.isColumnShown(column)
        );
    },
    isColumnShown (column) {
      return !(column.hideIf !== undefined && column.hideIf(this.groupingInternal))
        && ((!column.ifGrouping && !column.ifNotGrouping)
          || (this.groupingActive && column.ifGrouping)
          || (!this.groupingActive && column.ifNotGrouping));
    },
    /* TODO: Optimize */
    onAllGroupingClick () {
      this.noEvents         = true;
      this.groupingInternal = [];
      this.groupableColumns.forEach((column) => {
        this.groupingInternal.push(column.name);
        this.groupingChanged(column);
      });
      this.noEvents = false;
      this.$emit('sorting', this.sortingInternal);
      this.$emit('grouping', this.groupingInternal);
      this.$emit('shownColumns', this.shownColumnsInternal);
    },
    onNoneGroupingClick () {
      this.noEvents         = true;
      this.groupingInternal = [];
      this.groupableColumns.forEach((column) => {
        this.groupingChanged(column);
      });
      this.noEvents = false;
      this.$emit('sorting', this.sortingInternal);
      this.$emit('grouping', this.groupingInternal);
      this.$emit('shownColumns', this.shownColumnsInternal);
    },
    /* TODO: Optimize */
    onAllVisibilityClick () {
      this.noEvents             = true;
      this.shownColumnsInternal = [];
      this.columns.forEach((column) => {
        this.shownColumnsInternal.push(column.name);
        this.shownColumnsChanged(column);
      });
      this.noEvents = false;
      this.$emit('sorting', this.sortingInternal);
      this.$emit('grouping', this.groupingInternal);
      this.$emit('shownColumns', this.shownColumnsInternal);
    },
    onNoneVisibilityClick (noEvents = false) {
      this.noEvents             = true;
      this.shownColumnsInternal = [];
      this.noEvents             = false;
      if (!noEvents) {
        this.$emit('sorting', this.sortingInternal);
        this.$emit('grouping', this.groupingInternal);
        this.$emit('shownColumns', this.shownColumnsInternal);
      }
    },
  }
  ,
}
;
</script>

<style scoped
       lang="css">
  .column-switch {
    min-width: 46px;
  }

  .sort-icon {
    margin-left: 5px;
  }

  .data-table-row {
    width: 100%;
    overflow-x: auto;
  }

  .table-options-button {
    min-width: 40px;
  }

  .text-no-wrap {
    white-space: nowrap
  }

  .header-content {
    cursor: pointer
  }
</style>
<style lang="sass">
  @import '~@/Styles/variables.sass'

  $table-border-color: rgba(#000000, .12)
  $breakpoint-sm-max: 450px
  $table-stripe-color: rgba(#000000, .07)
  $light-theme-field-background: rgba(#000000, .05)
  $dark-theme-field-background: rgba(#ffffff, .07)
  $table-border: 1px solid $table-border-color

  .data-table-columns-settings.list--dense .list__item
    font-size: 16px

    .switch
      margin-left: 10px

    .justify-center
      justify-content: center !important

  table.trol-data-table
    border-spacing: 0
    empty-cells: show
    width: 100%

    thead
      text-align: left
      vertical-align: bottom
      font-weight: 700

    th, td
      line-height: 20px

      &:first-child
        border-left-width: 0

      margin: 0
      padding: .5rem .7rem

    &.compact
      th, td
        padding: .175rem .265rem

    &.loose
      th, td
        padding: 1rem

    &.bordered, &.cell-separator:not(.responsive):not(.flipped)
      border: $table-border

    &:not(.responsive):not(.flipped)
      &.horizontal-separator, &.cell-separator
        th, td
          border-bottom: $table-border

      &.vertical-separator, &.cell-separator
        th, td
          border-left: $table-border

          &:last-child
            border-right: $table-border

    &.striped-odd, &.striped
      tbody
        tr:nth-child(odd)
          background: $table-stripe-color

    &.striped-even
      tbody
        tr:nth-child(even)
          background: $table-stripe-color

    &.flipped
      display: flex
      overflow: hidden
      background: none

      thead
        display: flex
        flex-shrink: 0
        min-width: min-content

      tbody
        display: flex
        position: relative
        overflow-x: auto
        overflow-y: hidden

      tr
        display: flex
        flex-direction: column
        min-width: min-content
        flex-shrink: 0

      td, th
        display: block

      td
        background-image: none !important
        border-left: 0

      th:not(:last-child), td:not(:last-child)
        border-bottom: 0

      &.horizontal-separator, &.cell-separator
        tr
          border-left: $table-border
          border-right: $table-border

      &.vertical-separator, &.cell-separator
        th, td
          border-top: $table-border

          &:last-child
            border-bottom: $table-border

    @media (max-width $breakpoint-sm-max)
      &.responsive:not(.flipped)
        thead
          display: none

        tr, td
          display: block

        tr
          position: relative

        td:before
          content: attr(data-th)
          display: block
          text-align: left
          font-weight: bold

        &.horizontal-separator
          tr:not(:last-child)
            border-bottom: $table-border

        &.cell-separator
          tr
            border: $table-border

        &.vertical-separator, &.cell-separator
          th, td
            border-left: $table-border
            border-right: $table-border

  table.trol-data-table.highlight tbody tr
    transition: all .28s ease-in

    &:hover
      background: rgba(#297fff, .25)

    &.active
      background: rgba(#2196F3, .5)

  table.trol-data-table:not(.responsive):not(.flipped).horizontal-separator th,
  table.trol-data-table:not(.responsive):not(.flipped).cell-separator th
    border-bottom-width: 2px
    font-weight: normal
    font-size: 12px

  table.trol-data-table.horizontal-separator tr:last-child > td,
  table.trol-data-table.cell-separator tr:last-child > td
    border-bottom: none

  table.trol-data-table thead:not(.bottom-header) > tr:last-child > th
    border-bottom: $table-border-color 2px solid

  table.trol-data-table thead.bottom-header > tr:first-child > th
    border-top: $table-border-color 2px solid

  table.trol-data-table.vertical-separator tbody > tr > td:not(:first-child)
    border-left: $table-border-color 1px solid

  table.trol-data-table tfoot > tr:first-child > th,
  table.trol-data-table tfoot > tr:first-child > td
    border-top: $table-border-color 2px solid

  table.trol-data-table th#selection-column
    width: 44px

  table.trol-data-table th#selection-column div.v-input--selection-controls
    margin-top: 6px
    margin-left: 6px
    margin-bottom: 7px
    width: 24px

  table.trol-data-table td.selection-column div.v-input--selection-controls
    margin-top: 0
    width: 20px
    padding-top: 0
    margin-left: 6px
</style>
