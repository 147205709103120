import Router from '@/router';
import {Storage} from '@/services/LocalStorage';
import extend from 'lodash/extend';

const lsKey = 'trol-search.';

function hasStateInLS (key) {
  return Storage.has(lsKey + key);
}

function hasStateInUri () {
  const query = JSON.parse(JSON.stringify(Router.currentRoute.query));
  return !!query && !!query.search;
}

function getStateFromUri () {
  return JSON.parse(Router.currentRoute.query.search);
}

function getStateFromLS (key) {
  return Storage.get(lsKey + key);
}

function getPassedQuery () {
  let passedOptions = {};

  if (Router.currentRoute.query.query) {
    passedOptions = JSON.parse(Router.currentRoute.query.query);

    const query = extend({}, Router.currentRoute.query);
    delete query.query;
    Router.replace({query});
  }

  return passedOptions;
}

const SearchState = {
  hasState (key) {
    return hasStateInUri() || hasStateInLS(key);
  },

  restore (key, defaults) {
    const result = this.hasState(key)
      ? hasStateInUri()
        ? getStateFromUri()
        : getStateFromLS(key)
      : {};

    return extend({}, defaults, result, getPassedQuery());
  },

  save (key, state) {
    const routerQuery = JSON.parse(JSON.stringify(Router.currentRoute.query));
    const newSearch   = JSON.stringify(state);

    if (routerQuery.search === newSearch) return;

    routerQuery.search = newSearch;
    Storage.set(lsKey + key, state);
    Router.replace({
      query: routerQuery,
    });
  },
};

export {SearchState};
